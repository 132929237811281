import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useAtom } from 'jotai';

import { BillType, fetchBillType, FetchBillTypePayload } from 'api/index';
import { billTypeAtom, modalInfoAtom } from 'store/globalStateAtom';

function useFetchBillType() {
  const { t } = useTranslation();
  const [, setBillType] = useAtom(billTypeAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);

  return useMutation<BillType, AxiosError, FetchBillTypePayload>(
    (payload) => fetchBillType(payload),
    {
      onSuccess: (data) => {
        setBillType(data);
      },
      onError: () => {
        setModalInfo({
          title: '에러가 발생했습니다.',
          description: t('error_default_desc'),
          icon: 'ALERT',
        });
      },
    }
  );
}

export default useFetchBillType;
