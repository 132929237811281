import { useState } from 'react';

function useButtonClicked() {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleButtonClick = () => {
    if (isButtonClicked) return;
    setIsButtonClicked(true);
  };

  return { isButtonClicked, handleButtonClick };
}

export default useButtonClicked;
