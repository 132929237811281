import React from 'react';
import { useAtom } from 'jotai';
import { languageAtom } from 'store/globalStateAtom';
import { useNavigate } from 'react-router';

type Props = {
  lang: 'jp' | 'ch' | 'en' | 'ko';
  imgUrl: string;
};
function BtnItem({ lang, imgUrl }: Props) {
  const navigate = useNavigate();
  const [, setLanguage] = useAtom(languageAtom);

  const onClick = () => {
    setLanguage(lang);
    navigate('/passport-certification');
  };

  return (
    <button
      onClick={onClick}
      className='w-full bg-white py-10 px-14 flex items-center rounded-20 shadow-default active:opacity-80'
    >
      <img src={imgUrl} width={120} height={120} alt='flag' />
      <span className='text-mono-80 font-bold text-head-01 ml-10'>
        {lang === 'jp'
          ? '日本語'
          : lang === 'ch'
          ? '中文'
          : lang === 'ko'
          ? '한국어'
          : 'English'}
      </span>
    </button>
  );
}

export default BtnItem;
