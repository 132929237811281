import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getRefundInfo, GetRefundInfoReqBody, RefundInfo } from 'api';
import { Error } from 'api/config';
import {
  emissionKioskStatusAtom,
  kioskStatusAtom,
  modalInfoAtom,
  refundInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';
import { calculateCount } from 'utils';

function useGetRefundInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setRefundInfo] = useAtom(refundInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [withdrawalInfo, setWithdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [kioskStatus] = useAtom(kioskStatusAtom);
  const [emissionKioskStatus] = useAtom(emissionKioskStatusAtom);

  return useMutation<RefundInfo, AxiosError<Error>, GetRefundInfoReqBody>(
    (payload) => getRefundInfo(payload),
    {
      retry: (failureCount, error) => {
        if (error.message === 'Network Error' && failureCount < 3) {
          return true;
        }
        return false;
      },
      onSuccess: (data) => {
        const { bd1Error, bd2Error, bd3Error, hp1Error } = kioskStatus;
        const kioskErrorStatus = { bd1Error, bd2Error, bd3Error, hp1Error };

        console.log(
          '계산: ',
          calculateCount(data.totalRefund, kioskStatus, emissionKioskStatus),
        );
        console.log(
          'data: ',
          data.refundInfoList.map((v) => v.refundIndex),
        );
        setWithdrawalInfo({
          ...withdrawalInfo,
          totalRefund: data.totalRefund,
        });
        setRefundInfo({
          refundInfoList: data.refundInfoList,
          ...calculateCount(
            data.totalRefund,
            kioskErrorStatus,
            emissionKioskStatus,
          ),
        });
        navigate('/refund', {
          state: {
            data,
          },
        });
      },
      onError: (err) => {
        const errCode = err.response?.data.code;
        switch (errCode) {
          //* 환급 가능 건이 없는 경우
          case 'R0001':
          case 'I0001':
            setModalInfo({
              title: t('error_passport_title'),
              description: t('error_passport_desc'),
              icon: 'ALERT',
              btnText: t('modal_confirm'),
            });
            break;
          case 'K0003':
            //* 재외국민 or 체류만료일 3개월 이상 or 550만원 이상 시술건 밖에 없는 경우
            setModalInfo({
              title: t('error_refund_title'),
              description: t('error_refund_desc'),
              icon: 'ALERT',
              btnText: t('modal_confirm'),
            });
            break;
          case 'K0008':
            //* 이미 환급된 건이 있으면서 새로운 환급건이 없는경우
            setModalInfo({
              title: t('error_refund_complete_title'),
              description: t('error_refund_complete_desc'),
              icon: 'ALERT',
              btnText: t('modal_confirm'),
            });
            break;
          default:
            if (err.message === 'Network Error') {
              setModalInfo({
                title: t('error_network_title'),
                description: t('error_network_desc'),
                icon: 'ALERT',
                btnText: t('modal_confirm'),
              });
            } else {
              setModalInfo({
                title: '에러가 발생했습니다.',
                description: t('error_default_desc'),
                icon: 'ALERT',
                btnText: t('modal_confirm'),
              });
            }
            break;
        }
      },
    },
  );
}

export default useGetRefundInfo;
