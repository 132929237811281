import React from 'react';
import { useAtom } from 'jotai';

import check from 'images/common/check_round.png';
import alert from 'images/common/alert_round.png';
import error from 'images/common/error_modal_icon.png';
import reset from 'images/common/reset_white.png';
import { modalInfoAtom } from 'store/globalStateAtom';

function Modal() {
  const [modalInfo, setModalInfo] = useAtom(modalInfoAtom);

  const closeModal = () => {
    setModalInfo(null);
  };

  const onClickBtn = () => {
    modalInfo?.btnCallback && modalInfo.btnCallback();
    !modalInfo?.preventDefault && closeModal();
  };

  const onClickSubBtn = () => {
    modalInfo?.subBtnCallback && modalInfo.subBtnCallback();
    !modalInfo?.subBtnPreventDefault && closeModal();
  };

  const handleContextMenu: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {modalInfo && !modalInfo.videoUrl ? (
        <div
          onContextMenu={handleContextMenu}
          className="fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50"
        >
          <div className="min-w-[840px] bg-white rounded-20 flex-col items-center py-14 px-10 text-center">
            {modalInfo.icon && (
              <img
                src={
                  modalInfo.icon === 'CHECK'
                    ? check
                    : modalInfo.icon === 'ALERT'
                    ? alert
                    : error
                }
                width={modalInfo.icon === 'ERROR' ? 240 : 120}
                height={120}
                alt="modal_icon"
                className="mx-auto"
              />
            )}
            <h2 className="text-head-01 font-bold mt-8 mb-3 text-mono-80">
              {modalInfo.title}
            </h2>
            {modalInfo.pointColorText && (
              <p className="text-subHead-01 font-semibold text-blue-100 whitespace-pre-wrap text-center">
                {modalInfo.pointColorText}
              </p>
            )}
            <p className="text-subHead-01 font-semibold text-mono-50 whitespace-pre-wrap text-center">
              {modalInfo.description}
            </p>
            <div className="flex gap-6 justify-center">
              {!modalInfo.btnDisabled && (
                <button
                  onClick={onClickBtn}
                  className={`rounded-100 ${
                    modalInfo.btnClass ||
                    (modalInfo.subBtnText
                      ? 'bg-mono-10 text-mono-80 active:bg-mono-20'
                      : 'bg-blue-100 text-white active:bg-blue-150')
                  } text-body-01 py-5 ${
                    modalInfo.btnClass ? 'flex-1' : 'px-40'
                  } font-semibold mt-9`}
                  style={{ boxShadow: '0px 4px 10px 0px #ADADAD' }}
                >
                  {modalInfo.btnText || '확인'}
                </button>
              )}
              {modalInfo.subBtnText && (
                <button
                  onClick={onClickSubBtn}
                  className={`rounded-100 ${
                    modalInfo.subBtnClass
                      ? modalInfo.subBtnClass
                      : 'bg-warning-100 active:bg-warning-150 text-white'
                  } text-body-01 py-5 flex-1 font-semibold mt-9`}
                  style={{ boxShadow: '0px 4px 10px 0px #ADADAD' }}
                >
                  {modalInfo.subBtnText}
                </button>
              )}
            </div>
            {modalInfo.imageUrl && (
              <img
                src={modalInfo.imageUrl}
                alt="modal_info"
                className="w-[868px] mt-8"
              />
            )}
          </div>
        </div>
      ) : (
        modalInfo &&
        modalInfo.videoUrl && (
          <div
            onContextMenu={handleContextMenu}
            className="fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50"
          >
            <div className="min-w-[840px] bg-white rounded-20 flex-col items-center py-14 px-10 text-center">
              <video
                preload="metadata"
                autoPlay
                muted
                playsInline
                controls={false}
                loop
                className="w-[768px] h-[1366px] object-cover mb-10 rounded-lg"
              >
                <source src={modalInfo.videoUrl} type="video/mp4" />
              </video>
              <p className="text-2xl text-subHead-01 font-semibold text-mono-80 whitespace-pre-wrap text-center">
                {modalInfo.description}
              </p>
              <div className="flex gap-6 justify-center">
                {!modalInfo.btnDisabled && (
                  <button
                    onClick={onClickBtn}
                    className={`rounded-100 ${
                      modalInfo.btnClass ||
                      (modalInfo.subBtnText
                        ? 'bg-mono-10 text-mono-80 active:bg-mono-20'
                        : 'bg-blue-100 text-white active:bg-blue-150')
                    } text-body-01 py-5 font-semibold mt-9`}
                  >
                    {modalInfo.btnText || '확인'}
                  </button>
                )}
                {modalInfo.subBtnText && (
                  <button
                    onClick={onClickSubBtn}
                    className={`rounded-100 ${
                      modalInfo.subBtnClass
                        ? modalInfo.subBtnClass
                        : 'bg-warning-100 active:bg-warning-150 text-white'
                    } text-body-01 py-5 font-semibold mt-9`}
                  >
                    {modalInfo.icon && (
                      <img
                        src={modalInfo.icon === 'RESET' && reset}
                        className="w-9 h-9"
                        alt="reset"
                      />
                    )}
                    {modalInfo.subBtnText}
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

export default Modal;
