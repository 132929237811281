import { useAtom } from 'jotai';

import { billTypeAtom } from 'store/globalStateAtom';
import { formatBillType } from 'utils';

type Props = {
  type: string;
  isError: boolean;
  count: number;
};
function CashAmount({ type, isError, count }: Props) {
  const [billType] = useAtom(billTypeAtom);

  return (
    <div className='flex-1 flex flex-col gap-6 p-8 rounded-20 bg-white justify-between items-center'>
      <h4 className={isError ? 'text-warning-100' : ''}>
        {formatBillType(billType[type])} ({type.toUpperCase()})
      </h4>
      <p>
        {count || 0} / {type === 'hp1' ? 300 : 2000}
      </p>
    </div>
  );
}

export default CashAmount;
