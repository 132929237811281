import React from 'react';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';

import { billTypeAtom, kioskStatusAtom } from 'store/globalStateAtom';
import CashAmount from 'components/Setting/CashAmount';
import errorIcon from 'images/common/error_round.png';
import checkRoundIcon from 'images/common/check_round.png';
import cashIcon from 'images/common/cash.png';
import { getCashInfo, getIsEnoughMoneyLeft } from 'utils';

function CurrentCashAmount() {
  const navigate = useNavigate();
  const [kioskStatus] = useAtom(kioskStatusAtom);
  const [billType] = useAtom(billTypeAtom);

  const haveAnyCountErrors = !getIsEnoughMoneyLeft({ billType, kioskStatus });
  const cashInfo = getCashInfo({ billType, kioskStatus });

  const onChargeCash = () => {
    navigate('/setting/cash-charging');
  };

  return (
    <>
      <div className='flex gap-6 items-center mt-40 mb-6 '>
        <h3 className='text-head-02 font-semibold text-mono-80'>현금 잔량</h3>
      </div>
      <div className='flex gap-3 items-start'>
        <img
          src={haveAnyCountErrors ? errorIcon : checkRoundIcon}
          className='w-9 h-9 mt-1'
          alt='kiosk_status'
        />
        <p className='text-body-01 font-semibold text-mono-80'>
          {haveAnyCountErrors
            ? '현금을 보충해주세요.'
            : '현금 잔량이 충분합니다.'}
        </p>
      </div>
      <div className='flex gap-5 text-body-01 font-semibold text-mono-80 mt-8'>
        {cashInfo.map((v, i) => (
          <CashAmount key={i} {...v} />
        ))}
      </div>
      <button
        onClick={onChargeCash}
        className='rounded-100 bg-blue-100 py-5 px-44 flex text-white text-body-01 font-semibold items-center gap-2 mx-auto mt-10 active:bg-blue-150'
      >
        <img src={cashIcon} className='w-9 h-9' alt='reset' />
        보충하기
      </button>
    </>
  );
}

export default CurrentCashAmount;
