import axios from 'axios';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';

import { modalInfoAtom } from 'store/globalStateAtom';

function useCheckCurrentVersion() {
  const location = useLocation();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const getCurrentVersion = () => {
    return localStorage.getItem('webVersion');
  };

  const fetchManifest = async () => {
    const response = await axios.get(
      'https://s3.ap-northeast-2.amazonaws.com/centerkiosk.ktaxpay.com/manifest.json',
    );
    return response.data;
  };

  useQuery(['manifest', location], fetchManifest, {
    onSuccess: (res) => {
      const currentVersion = localStorage.getItem('webVersion');
      const newVersion = res.web_version;
      localStorage.setItem('webVersion', newVersion);
      if (!currentVersion) {
        return;
      }
      if (currentVersion !== newVersion) {
        setModalInfo({
          title: 'KTP Tax Refund Kiosk',
          btnCallback: () => {
            window.location.reload();
          },
          btnText: 'Click >',
        });
      }
    },
    staleTime: 0,
  });
  return { getCurrentVersion };
}

export default useCheckCurrentVersion;
