import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';
import useLogging from 'hooks/useLogging';
import connectPort from 'images/common/connect_port_guide.png';
import {
  adminLoginInfoAtom,
  billTypeAtom,
  CountInfo,
  emissionKioskStatusAtom,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
  moneyNotWithdrawnAtom,
  refundInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';
import {
  calculateBC,
  calculateFCC,
  convertErrorCode,
  getIsKioskError,
  getIsLackOfCash,
} from 'utils';
import useCompleteRefunds from 'hooks/useCompleteRefunds';

function useSerialCommunication() {
  const location = useLocation();

  const { t } = useTranslation();
  const STX = 0xfe;
  const { mutate } = useUpdateKioskStatus();
  const { isLoading: refundCompleteLoading, mutate: completeRefunds } =
    useCompleteRefunds();
  const { mutate: logOnServer } = useLogging();
  const navigate = useNavigate();
  const timerRef = useRef<any>(null);

  const [moneyNotWithdrawn, setMoneyNotWithdrawn] = useAtom(
    moneyNotWithdrawnAtom,
  );
  const [port, setPort] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [refundInfo, setRefundInfo] = useAtom(refundInfoAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [billType] = useAtom(billTypeAtom);
  const [withdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [emissionKioskStatus] = useAtom(emissionKioskStatusAtom);

  // * 아래 useRef 사용 이유 *
  //- processValue 함수가 내부 함수여서 외부 상태들의 최신화된값을 가져오지 못함
  //- 내부함수에서는 ref를 통해서만 최신값을 가져올 수 있어서 useRef 사용
  const currentMoneyNotWithdrawnRef = useRef(moneyNotWithdrawn);
  const currentKioskStatusRef = useRef(kioskStatus);
  const currentLoginInfoRef = useRef(loginInfo);
  const resetDefaultInquiryCountRef = useRef(0);
  const emitDefaultInquiryCountRef = useRef(0);
  const refundInfoRef = useRef(refundInfo);
  const currentCMDRef = useRef<'RESET' | 'EMIT' | null>();
  const currentLocationRef = useRef<string>('');
  const errorDetectionStatusRef = useRef({ bd1: 0, bd2: 0, bd3: 0, hp1: 0 }); // 최초 에러 확인 유무 | 0: 정상, 1: 최초에러 감지, 2: 상세 에러상태 확인
  const withdrawalTargetCountRef = useRef({
    bd1Count: 0,
    bd2Count: 0,
    bd3Count: 0,
    hp1Count: 0,
  });
  const withdrawalInfoRef = useRef(withdrawalInfo);
  const emissionKioskStatusRef = useRef(emissionKioskStatus); // 교차방출 상태

  const log = (data: string) => {
    logOnServer({
      controlCode: loginInfo?.controlCode,
      data,
    });
  };
  const goRefundComplete = (isWithdrawalNotCompleted?: boolean) => {
    console.log('미방출 금액', currentMoneyNotWithdrawnRef);
    log(`환급액 방출 ${isWithdrawalNotCompleted ? '실패' : '성공'}:: ${
      isWithdrawalNotCompleted ? '이메일 입력' : '완료'
    } 페이지로 이동 - 키오스크 이름: ${
      currentLoginInfoRef.current.name
    }, controlCode: ${
      currentLoginInfoRef.current.controlCode
    }, - passportNumber: ${withdrawalInfoRef.current.passportNumber}, 
      \n- 방출 정보: bd1Count:${
        refundInfoRef.current.bd1Count -
        currentMoneyNotWithdrawnRef.current.bd1Count
      }, bd2Count:${
      refundInfoRef.current.bd2Count -
      currentMoneyNotWithdrawnRef.current.bd2Count
    }, bd3Count:${
      refundInfoRef.current.bd3Count -
      currentMoneyNotWithdrawnRef.current.bd3Count
    }, hp1Count:${
      refundInfoRef.current.hp1Count -
      currentMoneyNotWithdrawnRef.current.hp1Count
    }, 
      \n- 미방출 금액: bd1Count:${
        currentMoneyNotWithdrawnRef.current.bd1Count
      }, bd2Count:${currentMoneyNotWithdrawnRef.current.bd2Count}, bd3Count:${
      currentMoneyNotWithdrawnRef.current.bd3Count
    }, hp1Count:${currentMoneyNotWithdrawnRef.current.hp1Count}`);
    if (isWithdrawalNotCompleted) {
      completeRefunds({
        controlCode: currentLoginInfoRef.current.controlCode,
        isWithdrawalNotCompleted: true,
        ...refundInfoRef.current,
        hp1Count:
          refundInfoRef.current.hp1Count -
          currentMoneyNotWithdrawnRef.current.hp1Count,
        bd1Count:
          refundInfoRef.current.bd1Count -
          currentMoneyNotWithdrawnRef.current.bd1Count,
        bd2Count:
          refundInfoRef.current.bd2Count -
          currentMoneyNotWithdrawnRef.current.bd2Count,
        bd3Count:
          refundInfoRef.current.bd3Count -
          currentMoneyNotWithdrawnRef.current.bd3Count,
      });
    } else {
      completeRefunds({
        controlCode: currentLoginInfoRef.current.controlCode,
        ...refundInfoRef.current,
      });
    }
  };
  useEffect(() => {
    currentKioskStatusRef.current = kioskStatus;
  }, [kioskStatus]);

  useEffect(() => {
    currentLoginInfoRef.current = loginInfo;
  }, [loginInfo]);

  useEffect(() => {
    currentMoneyNotWithdrawnRef.current = moneyNotWithdrawn;
  }, [moneyNotWithdrawn]);

  useEffect(() => {
    refundInfoRef.current = refundInfo;
  }, [refundInfo]);

  useEffect(() => {
    withdrawalInfoRef.current = withdrawalInfo;
  }, [withdrawalInfo]);

  useEffect(() => {
    emissionKioskStatusRef.current = emissionKioskStatus;
  }, [emissionKioskStatus]);

  useEffect(() => {
    currentLocationRef.current = location.pathname;
  }, [location.pathname]);

  const openInitializationModal = () => {
    const isFirstSerialConnection = getIsFistSerialConnection();
    setModalInfo({
      title: '키오스크 기기 연결을 허용해주세요.',
      pointColorText: isFirstSerialConnection
        ? undefined
        : 'スタッフでお問い合わせください。请咨询职员。Please ask for assistance. ',
      description: `${
        isFirstSerialConnection
          ? '키오스크를 사용하기 위해 연결이 필요합니다.'
          : '페이지 새로고침이 감지되어 키오스크 연결이 끊어졌습니다.'
      }\n아래 사진을 참고해 연결을 진행해주세요.`,
      btnText: '확인',
      icon: 'CHECK',
      imageUrl: connectPort,
      btnCallback: initialize,
    });
  };
  const getIsFistSerialConnection = () => {
    const isFirstSerialConnection = localStorage.getItem(
      'isFirstSerialConnection',
    );
    if (!isFirstSerialConnection) {
      localStorage.setItem('isFirstSerialConnection', 'NO');
      return true;
    } else {
      return false;
    }
  };
  const initialize = async () => {
    try {
      //@ts-ignore
      const port = await navigator.serial.requestPort();
      log('포트 연결 성공');
      setPort(port);
      await port.open({ baudRate: 9600 });
      log('포트 열기 성공');
    } catch (err) {
      setModalInfo({
        title: '웹 사이트 연결을 확인해주세요.',
        description: `이미 키오스크 연결이 완료된 페이지가 실행 중입니다.\n현재 페이지를 닫고 이전에 실행 중인 페이지를 사용해주세요.`,
        btnText: '닫기',
        preventDefault: true,
        btnCallback: openInitializationModal,
        icon: 'ALERT',
      });
      log('포트 열기 실패');
    }
  };

  /** ** 명령어 전송 ** */
  async function sendCommandToSerialPort(commandBuffer: Uint8Array) {
    log(`요청 전송: ${commandBuffer}`);

    //@ts-ignore
    const writer = await port.writable.getWriter();
    await writer.write(commandBuffer);
    await writer.releaseLock();
  }
  /** ** 현금 방출 ** */
  async function emitBills(countInfo: CountInfo) {
    setIsLoading(true);
    const { bd1ErrorCode, bd2ErrorCode, bd3ErrorCode, hp1Error } =
      currentKioskStatusRef.current;

    if (bd2ErrorCode && bd3ErrorCode) {
      setIsLoading(false);
      return navigate('/notwithdrawal');
    }

    currentCMDRef.current = 'EMIT';
    // 패킷 정보
    const CMD = 0x12;
    const BC = calculateBC(CMD);

    //방출 목표 수량 저장
    withdrawalTargetCountRef.current = countInfo;

    const { hp1Count, bd1Count, bd2Count, bd3Count } = countInfo;
    // 방출할 금액보다 남아있는 잔량이 적을 경우 notwithdrawal 페이지로 이동
    if (getIsLackOfCash({ billType, kioskStatus, countInfo })) {
      setIsLoading(false);
      setModalInfo({
        title: t('error_cash_title'),
        description: t('error_cash_desc'),
        btnText: t('modal_confirm'),
        btnCallback: () => {
          navigate('/notwithdrawal');
        },
        icon: 'CHECK',
      });
      return;
    }

    // 데이터 필드 설정
    const dataArray = [
      hp1Count,
      0x00,
      bd1Count,
      bd2Count,
      bd3Count,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandPacket = new Uint8Array([...packetExcludingFcc, fcc]);

    // 키오스크 보드로 패킷 전송
    try {
      await sendCommandToSerialPort(commandPacket);
    } catch (err) {
      //응답
      console.error(err);
    }
  }
  /**** 기본 상태 조회 or 에러 조회 ** */
  const fetchKioskStatus = async (type: 'DEFAULT' | 'ERROR' = 'DEFAULT') => {
    const CMD = 0x11;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      type === 'DEFAULT' ? 0b00000000 : 0b00001000,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      await sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      console.error(err);
    }
  };
  /**** BD1, BD2, BD3, HP1 리셋 ** */
  const resetKiosk = async () => {
    setIsLoading(true);
    currentCMDRef.current = 'RESET';
    // 패킷 정보
    const CMD = 0x10;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      0x00, 0b00001111, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      setIsLoading(false);
      console.error(err);
    }
  };

  /** ** 응답 수신 ** */
  const readResponse = async () => {
    if (!port) return;
    //@ts-ignore
    const reader = port.readable.getReader();
    log(`리더기 생성 완료: ${reader}`);

    let RecvComBuffer: Array<number> = [];
    let RS232_RXStep = 0;

    const processValue = (tmp: number) => {
      if (RS232_RXStep === 0) {
        if (tmp !== 0xfe) {
          if (tmp === 0x11) {
            //ACK
            log('ACK');
          } else if (tmp === 0xee) {
            //NAK
            log('NAK');
          } else {
            log(`시리얼 통신 에러:: 알수 없는 패킷 헤더 ${tmp}`);
          }
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else if (RS232_RXStep === 1) {
        if (tmp !== 18) {
          RS232_RXStep = 0;
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else {
        RecvComBuffer[RS232_RXStep++] = tmp;
        if (RS232_RXStep === 19) {
          RS232_RXStep = 0;
          console.log('RecvComBuffer::', RecvComBuffer);
          const chksum = calculateFCC(RecvComBuffer.slice(0, -1));
          if (
            chksum === RecvComBuffer[18] &&
            (RecvComBuffer[17] === 0x13 || RecvComBuffer[17] === 0x15)
          ) {
            // 들어온 FCC와 체크섬 비교 & 버전 체크
            const CMD = RecvComBuffer[2];
            switch (CMD) {
              case 0x13: {
                // 상태 조회 응답
                if (timerRef.current) {
                  clearTimeout(timerRef.current);
                }
                const [statusArr1, statusArr2, ...countArr] =
                  RecvComBuffer.slice(3, -1).map((v, i) => {
                    if (i < 2) {
                      return v
                        .toString(2)
                        .padStart(8, '0')
                        .split('')
                        .map(Number);
                    } else {
                      return +v;
                    }
                  });

                const isEmitting = (statusArr1 as number[])[4];
                if (isEmitting) {
                  console.log('isEmitting', isEmitting);
                  return;
                }

                //* 방출 중이면 아래 로직 실행X
                log(`응답 수신(0x13 기본 조회) : ${RecvComBuffer}`);
                const hp1Error = Boolean((statusArr1 as number[])[1]);
                const bd1Error = Boolean((statusArr2 as number[])[7]);
                const bd2Error = Boolean((statusArr2 as number[])[6]);
                const bd3Error = Boolean((statusArr2 as number[])[5]);

                /* 리셋(0x10) 요청시 자동 기본 조회(0x13) 응답 처리*/
                //- 리셋했을 때 에러 없으면: 자동 기본 조회 응답 1회만 옴
                //- 리셋했을 때 여전히 에러있으면: 자동 기본 조회 응답 2회 옴
                if (currentCMDRef.current === 'RESET') {
                  resetDefaultInquiryCountRef.current++;
                  if (resetDefaultInquiryCountRef.current === 1) {
                    if (hp1Error || bd1Error || bd2Error || bd3Error) {
                      //리셋시 에러 있으면 2회 기본 조회할 경우,
                      //첫번째 조회 응답은 부정확하게 와서 break로 나가기(2번째때 처리)
                      log(
                        '리셋시 에러 존재(2회 기본 조회 응답 중 1회) - break',
                      );
                      break;
                    } else {
                      //리셋시 에러 없어서 기본 조회 1회만 하고 끝남. 밑에서 상태 업데이트
                      resetDefaultInquiryCountRef.current = 0;
                      errorDetectionStatusRef.current = {
                        bd1: 0,
                        bd2: 0,
                        bd3: 0,
                        hp1: 0,
                      };
                      log(
                        '리셋시 에러 없음(1회 기본 조회 응답 중 1회) - 응답 처리',
                      );
                    }
                  } else {
                    //기본 조회 2회 진행 중
                    resetDefaultInquiryCountRef.current = 0;
                    errorDetectionStatusRef.current = {
                      bd1: 0,
                      bd2: 0,
                      bd3: 0,
                      hp1: 0,
                    };
                    log(
                      '리셋시 에러 존재(2회 기본 조회 응답 중 2회) - 응답 처리',
                    );
                  }
                }
                // console.log('currentCMDRef Before: ', currentCMDRef.current);
                /* 방출(0x12) 요청시 자동 기본 조회(0x13) 응답 처리*/
                // - 방출 성공시: 자동 기본 조회 응답 1회만 옴
                // - 방출 실패시: 자동 기본 조회 응답 2회옴
                if (currentCMDRef.current === 'EMIT') {
                  emitDefaultInquiryCountRef.current++;
                  log('방출(기본 조회 응답 1회) - 응답 처리');
                  if (emitDefaultInquiryCountRef.current === 2) {
                    //성공 방출 두번째 자동 기본 조회시 더 차감해서 업데이트 하지 않도록 break로 나가기
                    log(
                      '방출시 에러 없음(2회 기본 조회 응답 중 2회) - 초기화 + break',
                    );
                    emitDefaultInquiryCountRef.current = 0;
                    // currentCMDRef.current = null;
                    break;
                  }
                }
                // console.log('currentCMDRef After: ', currentCMDRef.current);

                //잔돈 수량 및 키오스트 상태 서버에 저장
                const hp1Count = countArr[6] as number;
                const bd1Count = countArr[8] as number;
                const bd2Count = countArr[9] as number;
                const bd3Count = countArr[10] as number;

                const newKioskStatus =
                  currentCMDRef.current === 'EMIT'
                    ? //* 방출일 경우: 잔돈 차감한 값, 에러 여부 업데이트
                      {
                        ...currentKioskStatusRef.current,
                        hp1TotalCount:
                          currentKioskStatusRef.current.hp1TotalCount -
                          hp1Count,
                        bd1TotalCount:
                          currentKioskStatusRef.current.bd1TotalCount -
                          bd1Count,
                        bd2TotalCount:
                          currentKioskStatusRef.current.bd2TotalCount -
                          bd2Count,
                        bd3TotalCount:
                          currentKioskStatusRef.current.bd3TotalCount -
                          bd3Count,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                      }
                    : {
                        //* 리셋일 경우: 에러 여부와 에러 여부가 0이면 에러 코드 0으로 업데이트
                        ...currentKioskStatusRef.current,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                        bd1ErrorCode: bd1Error
                          ? currentKioskStatusRef.current.bd1ErrorCode
                          : 0,
                        bd2ErrorCode: bd2Error
                          ? currentKioskStatusRef.current.bd2ErrorCode
                          : 0,
                        bd3ErrorCode: bd3Error
                          ? currentKioskStatusRef.current.bd3ErrorCode
                          : 0,
                      };
                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;
                // console.log('업데이트 전');
                // 방출 종료 후 키오스크 상태 저장
                mutate({
                  type: 'WITHDRAWAL',
                  controlCode: currentLoginInfoRef.current.controlCode,
                  ...newKioskStatus,
                });
                // console.log('업데이트 후');
                if (currentCMDRef.current === 'EMIT') {
                  const newMoneyWithDrawn = {
                    hp1Count:
                      withdrawalTargetCountRef.current.hp1Count - hp1Count,
                    bd1Count:
                      withdrawalTargetCountRef.current.bd1Count - bd1Count,
                    bd2Count:
                      withdrawalTargetCountRef.current.bd2Count - bd2Count,
                    bd3Count:
                      withdrawalTargetCountRef.current.bd3Count - bd3Count,
                  };
                  setMoneyNotWithdrawn(newMoneyWithDrawn);
                  currentMoneyNotWithdrawnRef.current = newMoneyWithDrawn;
                  log(
                    `- 키오스크 이름: ${currentLoginInfoRef.current.name}, controlCode: ${currentLoginInfoRef.current.controlCode}, - passportNumber: ${withdrawalInfoRef.current.passportNumber}, 미방출 수량: bd1Count:${newMoneyWithDrawn.bd1Count}, bd2Count:${newMoneyWithDrawn.bd2Count}, bd3Count:${newMoneyWithDrawn.bd3Count}, hp1Count:${newMoneyWithDrawn.hp1Count}`,
                  );
                }

                // 최초로 에러가 났을 경우 에러난 방출기 상태 업데이트 0 => 1
                // 0 = 정상, 1 = 최초에러, 2 = 에러상세조회
                const handleKisokError = (errorDispenserName: string) => {
                  if (
                    // @ts-ignore
                    errorDetectionStatusRef.current[errorDispenserName] === 0
                  ) {
                    // @ts-ignore
                    errorDetectionStatusRef.current[errorDispenserName]++;
                    fetchKioskStatus('ERROR');
                  }
                };

                // 최초로 에러났으면,
                if (hp1Error && errorDetectionStatusRef.current.hp1 === 0) {
                  handleKisokError('hp1');
                } else if (
                  bd1Error &&
                  errorDetectionStatusRef.current.bd1 === 0
                ) {
                  handleKisokError('bd1');
                } else if (
                  bd2Error &&
                  errorDetectionStatusRef.current.bd2 === 0
                ) {
                  handleKisokError('bd2');
                } else if (
                  bd3Error &&
                  errorDetectionStatusRef.current.bd3 === 0
                ) {
                  handleKisokError('bd3');
                } else {
                  // console.log('에러가 없을 경우');
                  //에러 안났으면 다음 스텝 진행
                  if (currentCMDRef.current === 'EMIT') {
                    // console.log('정상방출 성공');
                    setIsLoading(false);
                    setAdminLoginInfo({
                      isManager: null,
                      controlCode: null,
                    });
                    // log("환급액 방출 성공:: 환급 완료 페이지로 이동");
                    setTimeout(() => {
                      goRefundComplete();
                    }, 500);
                    // currentCMDRef.current = null;
                  } else if (currentCMDRef.current === 'RESET') {
                    // 미방출 금액은 리플랫 확인후 송금으로 진행함에 따라 키오스크 상태만 정상으로 변경
                    currentCMDRef.current = null;
                    setIsLoading(false);
                    setModalInfo({
                      title: '키오스크 상태가 정상입니다.',
                      description: '',
                      icon: 'CHECK',
                      btnCallback: () => {
                        log(
                          `리셋 성공:: - 키오스크 이름: ${currentLoginInfoRef?.current?.name}, controlCode: ${currentLoginInfoRef?.current?.controlCode}, - passportNumber: ${withdrawalInfoRef?.current?.passportNumber}`,
                        );
                        setAdminLoginInfo({
                          isManager: null,
                          controlCode: null,
                        });
                        navigate('/promotion');
                      },
                    });
                  }
                }
                break;
              }
              case 0x19: {
                // 방출기 에러코드 응답
                emitDefaultInquiryCountRef.current = 0;
                const [
                  statusArr1,
                  statusArr2,
                  bd1ErrorCode,
                  bd2ErrorCode,
                  bd3ErrorCode,
                ] = RecvComBuffer.slice(3, -1).map((v, i) => {
                  if (i < 2) {
                    return v.toString(2).padStart(8, '0').split('').map(Number);
                  } else {
                    return +v;
                  }
                });
                const hp1Error = Boolean((statusArr1 as number[])[1]);
                const bd1Error = Boolean((statusArr2 as number[])[7]);
                const bd2Error = Boolean((statusArr2 as number[])[6]);
                const bd3Error = Boolean((statusArr2 as number[])[5]);
                console.log(
                  'errorDetectionStatusRef 에러 조회 시작:',
                  errorDetectionStatusRef.current,
                );

                // 에러 상세값 전달하기 위한 상태 호출
                const newKioskStatus = {
                  ...currentKioskStatusRef.current,
                  bd1Error,
                  bd2Error,
                  bd3Error,
                  hp1Error,
                  bd1ErrorCode,
                  bd2ErrorCode,
                  bd3ErrorCode,
                };
                console.log('newKioskStatus error:', newKioskStatus);
                log(`에러 발생:: - 응답 수신(0x19 에러 조회): ${RecvComBuffer}, - 키오스크 이름: ${
                  currentLoginInfoRef.current.name
                }, - passportNumber: ${
                  withdrawalInfoRef.current.passportNumber
                }, 
                  \n- 에러 정보: ${JSON.stringify(newKioskStatus)}
                  \n- 방출 정보: bd1Count:${
                    refundInfoRef.current.bd1Count -
                    currentMoneyNotWithdrawnRef.current.bd1Count
                  }, bd2Count:${
                  refundInfoRef.current.bd2Count -
                  currentMoneyNotWithdrawnRef.current.bd2Count
                }, bd3Count:${
                  refundInfoRef.current.bd3Count -
                  currentMoneyNotWithdrawnRef.current.bd3Count
                }, hp1Count:${
                  refundInfoRef.current.hp1Count -
                  currentMoneyNotWithdrawnRef.current.hp1Count
                }, 
                  \n- 미방출 금액: bd1Count:${
                    currentMoneyNotWithdrawnRef.current.bd1Count
                  }, bd2Count:${
                  currentMoneyNotWithdrawnRef.current.bd2Count
                }, bd3Count:${
                  currentMoneyNotWithdrawnRef.current.bd3Count
                }, hp1Count:${currentMoneyNotWithdrawnRef.current.hp1Count}`);
                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;
                mutate({
                  type: 'WITHDRAWAL',
                  controlCode: currentLoginInfoRef.current.controlCode,
                  ...newKioskStatus,
                });

                /** 에러 코드가 16(잔량부족)일 경우 */
                if (
                  bd1ErrorCode === 16 ||
                  bd2ErrorCode === 16 ||
                  bd3ErrorCode === 16
                ) {
                  // 미방출 금액이 없을 경우 대체방출 실행하지 않음.
                  if (
                    currentMoneyNotWithdrawnRef.current.bd1Count === 0 &&
                    currentMoneyNotWithdrawnRef.current.bd2Count === 0 &&
                    currentMoneyNotWithdrawnRef.current.bd3Count === 0 &&
                    currentMoneyNotWithdrawnRef.current.hp1Count === 0
                  ) {
                    if (bd1ErrorCode === 16) {
                      errorDetectionStatusRef.current.bd1++;
                    }
                    if (bd2ErrorCode === 16) {
                      errorDetectionStatusRef.current.bd2++;
                    }
                    if (bd3ErrorCode === 16) {
                      errorDetectionStatusRef.current.bd3++;
                    }
                    setIsLoading(false);
                    return;
                  }
                }

                console.log('에러로 인한 대체방출 시작 로직 실행 전');
                if (hp1Error && errorDetectionStatusRef.current.hp1 === 1) {
                  // hp1 첫 에러
                  errorDetectionStatusRef.current.hp1++;
                  if (emissionKioskStatusRef.current.bd2Status && !bd2Error) {
                    console.log('hp1 에러로 인한 bd2 대체방출');
                    setRefundInfo({
                      ...refundInfoRef.current,
                      hp1Count:
                        refundInfoRef.current.hp1Count -
                        currentMoneyNotWithdrawnRef.current.hp1Count,
                      bd2Count: refundInfoRef.current.bd2Count + 1,
                    });
                    emitBills({
                      hp1Count: 0,
                      bd1Count: 0,
                      bd2Count: 1,
                      bd3Count: 0,
                    });
                  } else if (
                    emissionKioskStatusRef.current.bd3Status &&
                    !bd3Error
                  ) {
                    console.log('hp1 에러로 인한 bd3 대체방출');
                    setRefundInfo({
                      ...refundInfoRef.current,
                      hp1Count:
                        refundInfoRef.current.hp1Count -
                        currentMoneyNotWithdrawnRef.current.hp1Count,
                      bd3Count: refundInfoRef.current.bd3Count + 1,
                    });
                    emitBills({
                      hp1Count: 0,
                      bd1Count: 0,
                      bd2Count: 0,
                      bd3Count: 1,
                    });
                  }
                } else if (
                  bd1Error &&
                  errorDetectionStatusRef.current.bd1 === 1
                ) {
                  // bd1 첫 에러
                  errorDetectionStatusRef.current.bd1++;
                  console.log('bd1 에러로 인한 메인방출기 대체방출');
                  if (currentMoneyNotWithdrawnRef.current.bd1Count > 3) {
                    if (emissionKioskStatusRef.current.bd2Status && !bd2Error) {
                      setRefundInfo({
                        ...refundInfoRef.current,
                        bd1Count:
                          refundInfoRef.current.bd1Count -
                          currentMoneyNotWithdrawnRef.current.bd1Count,
                        bd2Count: refundInfoRef.current.bd2Count + 1,
                      });
                      emitBills({
                        hp1Count: 0,
                        bd1Count: 0,
                        bd2Count: 1,
                        bd3Count: 0,
                      });
                    } else if (
                      emissionKioskStatusRef.current.bd3Status &&
                      !bd3Error
                    ) {
                      setRefundInfo({
                        ...refundInfoRef.current,
                        bd1Count:
                          refundInfoRef.current.bd1Count -
                          currentMoneyNotWithdrawnRef.current.bd1Count,
                        bd3Count: refundInfoRef.current.bd3Count + 1,
                      });
                      emitBills({
                        hp1Count: 0,
                        bd1Count: 0,
                        bd2Count: 0,
                        bd3Count: 1,
                      });
                    }
                  } else {
                    let won1000 =
                      currentMoneyNotWithdrawnRef.current.bd1Count * 1000;
                    let won500Count = Math.floor(won1000 / 500);
                    setRefundInfo({
                      ...refundInfoRef.current,
                      bd1Count:
                        refundInfoRef.current.bd1Count -
                        currentMoneyNotWithdrawnRef.current.bd1Count,
                      hp1Count: won500Count,
                    });
                    emitBills({
                      hp1Count: won500Count,
                      bd1Count: 0,
                      bd2Count: 0,
                      bd3Count: 0,
                    });
                  }
                } else if (
                  bd3Error &&
                  errorDetectionStatusRef.current.bd3 === 1 &&
                  !bd2Error
                ) {
                  // bd3 첫 에러
                  errorDetectionStatusRef.current.bd3++;
                  console.log(
                    'bd3 에러로 인한 bd2 대체방출',
                    refundInfoRef.current,
                    currentMoneyNotWithdrawnRef.current,
                  );
                  setRefundInfo({
                    ...refundInfoRef.current,
                    hp1Count: refundInfoRef.current.hp1Count,
                    bd1Count: refundInfoRef.current.bd1Count,
                    bd2Count:
                      refundInfoRef.current.bd2Count +
                      currentMoneyNotWithdrawnRef.current.bd3Count,
                    bd3Count:
                      refundInfoRef.current.bd3Count -
                      currentMoneyNotWithdrawnRef.current.bd3Count,
                  });
                  console.log(
                    'bd3 에러로 인한 bd2 대체방출',
                    refundInfoRef.current,
                    currentMoneyNotWithdrawnRef.current,
                  );
                  emitBills({
                    hp1Count: refundInfoRef.current.hp1Count,
                    bd1Count: refundInfoRef.current.bd1Count,
                    bd2Count:
                      refundInfoRef.current.bd2Count +
                      currentMoneyNotWithdrawnRef.current.bd3Count,
                    bd3Count: 0,
                  });
                } else if (
                  bd2Error &&
                  errorDetectionStatusRef.current.bd2 === 1 &&
                  !bd3Error
                ) {
                  // bd2 첫 에러
                  errorDetectionStatusRef.current.bd2++;
                  console.log(
                    'bd2 에러로 인한 bd3 대체방출',
                    refundInfoRef.current,
                  );
                  setRefundInfo({
                    ...refundInfoRef.current,
                    hp1Count: refundInfoRef.current.hp1Count,
                    bd1Count: refundInfoRef.current.bd1Count,
                    bd2Count:
                      refundInfoRef.current.bd2Count -
                      currentMoneyNotWithdrawnRef.current.bd2Count,
                    bd3Count:
                      refundInfoRef.current.bd3Count +
                      currentMoneyNotWithdrawnRef.current.bd2Count,
                  });
                  emitBills({
                    hp1Count: refundInfoRef.current.hp1Count,
                    bd1Count: refundInfoRef.current.bd1Count,
                    bd2Count: 0,
                    bd3Count:
                      refundInfoRef.current.bd3Count +
                      currentMoneyNotWithdrawnRef.current.bd2Count,
                  });
                }
                console.log('에러로 인한 대체방출 시작 로직 실행 후');

                if (bd2Error && bd3Error) {
                  setRefundInfo({
                    ...refundInfoRef.current,
                    hp1Count:
                      refundInfoRef.current.hp1Count -
                      currentMoneyNotWithdrawnRef.current.hp1Count,
                    bd1Count:
                      refundInfoRef.current.bd1Count -
                      currentMoneyNotWithdrawnRef.current.bd1Count,
                    bd2Count:
                      refundInfoRef.current.bd2Count -
                      currentMoneyNotWithdrawnRef.current.bd2Count,
                    bd3Count:
                      refundInfoRef.current.bd3Count -
                      currentMoneyNotWithdrawnRef.current.bd3Count,
                  });
                  goRefundComplete(true);
                  setIsLoading(false);
                }
                console.log(
                  'errorDetectionStatusRef 에러 종료:',
                  errorDetectionStatusRef.current,
                );
                break;
              }
              default:
                log(`알 수 없는 CMD 에러 : ${CMD}`);
            }
          } else {
            // 수신 오류
            log('시리얼 통신 에러:: 유효하지 않은 형식의 응답 수신');
          }
        }
      }
    };

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          log('리더기 상태: DONE');
          await reader.releaseLock();
          break;
        }
        value.forEach(processValue);
      }
      return 0;
    } catch (err) {
      log(`시리얼 통신 에러:: 응답 수신 에러 ${err}`);
    }
  };

  useEffect(() => {
    if (port) {
      setModalInfo({
        title: '키오스크 연결이 완료되었습니다.',
        description: '',
        icon: 'CHECK',
        btnCallback: () => {
          readResponse();
        },
      });
    } else {
      openInitializationModal();
    }
  }, [port]);

  return {
    resetKiosk,
    readResponse,
    emitBills,
    initialize,
    isLoading: isLoading || refundCompleteLoading,
  };
}

export default useSerialCommunication;
