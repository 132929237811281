import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { notwithdrawal, NotwithdrawalReqBody } from 'api';
import { Error } from 'api/config';
import { modalInfoAtom } from 'store/globalStateAtom';

function useNotwithdrawal() {
  const navigate = useNavigate();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const { t } = useTranslation();

  return useMutation<number, AxiosError<Error>, NotwithdrawalReqBody>(
    (payload) => notwithdrawal(payload),
    {
      retry: false,
      onSuccess: () => {
        setModalInfo({
          title: t('notwithdrawal_completed_title'),
          description: t('notwithdrawal_completed_desc'),
          icon: 'CHECK',
          btnCallback: () => {
            navigate('/promotion');
          },
        });
      },
      onError: (err) => {},
    }
  );
}

export default useNotwithdrawal;
