import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { updateKioskStatus, UpdateKioskStatusReqPayload } from 'api';
import { modalInfoAtom } from 'store/globalStateAtom';

function useUpdateKioskStatus() {
  const { t } = useTranslation();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  return useMutation<number, AxiosError, UpdateKioskStatusReqPayload>(
    (payload) => updateKioskStatus(payload),
    {
      retry: (failureCount, error) => {
        if (error.message === 'Network Error' && failureCount < 3) {
          return true;
        }
        return false;
      },
      onSuccess: () => {
        console.log('🔆 Successfully saved kiosk status on the server...');
      },
      onError: (err) => {
        if (err.message === 'Network Error') {
          setModalInfo({
            title: t('error_network_title'),
            description: t('error_network_desc'),
            icon: 'ALERT',
          });
        } else {
          setModalInfo({
            title: '에러가 발생했습니다.',
            description: t('error_default_desc'),
            icon: 'ALERT',
          });
        }
      },
    }
  );
}

export default useUpdateKioskStatus;
