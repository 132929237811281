import { BillType } from 'api';
import { KioskStatus } from 'store/globalStateAtom';

/**세 자릿수마다 콤마 추가 */
export const comma = (text: string | number): string => {
  return text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const createCountMapping = (billType: BillType) => {
  const result = {};
  for (const key in billType) {
    //@ts-ignore
    result[billType[key]] =
      //@ts-ignore
      billType[key] === null
        ? //@ts-ignore
          [...(result[billType[key]] || []), key + 'Count']
        : key + 'Count';
  }
  return result;
};

type kioskErrorStatusType = {
  bd1Error: boolean;
  bd2Error: boolean;
  bd3Error: boolean;
  hp1Error: boolean;
};

type emissionKioskStatusType = {
  bd2Status: boolean;
  bd3Status: boolean;
};

export const calculateCount = (
  price: number | string,
  kioskErrorStatus: kioskErrorStatusType,
  emissionKioskStatus: emissionKioskStatusType
): {
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
  bd3Count: number;
} => {
  // bd1 = 천원, bd2, bd3 = 만원, hp1 = 오백원
  const result = { hp1Count: 0, bd1Count: 0, bd2Count: 0, bd3Count: 0 };
  const { bd1Error, bd2Error, bd3Error, hp1Error } = kioskErrorStatus;
  const { bd2Status, bd3Status } = emissionKioskStatus; // 키오스크 교차방출 상태

  let count10000won = Math.floor(+price / 10000); // 만원권 방출 개수
  let count1000won = Math.floor((+price - count10000won * 10000) / 1000); // 천원권 방출 개수
  let count500won = count1000won * 2; // 오백원 방출 개수

  if (bd2Error && bd3Error) {
    // 방출할 수 없는 상태
    return result;
  }

  // bd2가 방출 차례일 때 방출기를 bd2로 교체
  const crossEmissionStatus = (
    bd1Error: boolean,
    bd2Error: boolean,
    bd3Error: boolean,
    hp1Error: boolean,
    bd2Status: boolean,
    bd3Status: boolean
  ) => {
    const mainEmissionError = bd2Status ? bd2Error : bd3Error;
    // bd2 사용 상태이고, bd2에 에러가 없다면
    const subEmissionError = bd3Status ? bd3Error : bd2Error;
    const mainCounterKey = bd2Status ? 'bd2Count' : 'bd3Count';
    const subCounterKey = bd2Status ? 'bd3Count' : 'bd2Count';

    let result = { bd1Count: 0, bd2Count: 0, bd3Count: 0, hp1Count: 0 };

    if (!mainEmissionError) {
      if (bd1Error && !hp1Error) {
        // bd1 Error, 나머지 정상
        result[mainCounterKey] =
          count1000won > 3 ? count10000won + 1 : count10000won;
        if (count1000won <= 3) result.hp1Count = count500won;
      } else if (bd1Error && hp1Error) {
        // bd1 and hp1 Error, 나머지 정상
        result[mainCounterKey] = count10000won + 1;
      } else if (!bd1Error && !hp1Error) {
        // 모두 정상 or sub Emission Error
        result[mainCounterKey] = count10000won;
        result.bd1Count = count1000won;
      }
    } else {
      if (bd1Error && !hp1Error) {
        result[mainCounterKey] =
          count1000won > 3 ? count10000won + 1 : count10000won;
        if (count1000won <= 3) result.hp1Count = count500won;
      } else if (bd1Error && hp1Error) {
        result[mainCounterKey] = count10000won + 1;
      } else if (!bd1Error && !hp1Error) {
        result.bd1Count = count1000won;
      }
      result[subCounterKey] = count10000won;
    }
    return result;
  };

  return crossEmissionStatus(
    bd1Error,
    bd2Error,
    bd3Error,
    hp1Error,
    bd2Status,
    bd3Status
  );
};
type CustomBillTypeParams = {
  billType: BillType;
  kioskStatus: KioskStatus;
};
export const getIsKioskError = ({
  billType,
  kioskStatus,
}: CustomBillTypeParams) => {
  const { bd1Error, bd2Error, bd3Error, hp1Error } = kioskStatus;
  const errorObj = {
    bd1: bd1Error,
    bd2: bd2Error,
    bd3: bd3Error,
    hp1: hp1Error,
  };
  //@ts-ignore
  return (
    Object.keys(billType)
      //@ts-ignore
      .filter((v) => billType[v] !== null)
      //@ts-ignore
      .map((v) => errorObj[v])
      .reduce((prev, curr) => prev || curr, false)
  );
};
type GetIsLackOfCashParams = CustomBillTypeParams & {
  countInfo: {
    bd1Count: number;
    bd2Count: number;
    bd3Count: number;
    hp1Count: number;
  };
};
export const getIsLackOfCash = ({
  billType,
  kioskStatus,
  countInfo,
}: GetIsLackOfCashParams) => {
  const { bd1TotalCount, bd2TotalCount, bd3TotalCount, hp1TotalCount } =
    kioskStatus;
  const { bd1Count, bd2Count, bd3Count, hp1Count } = countInfo;
  const countObj = {
    bd1: {
      total: bd1TotalCount,
      emissionTarget: bd1Count,
    },
    bd2: {
      total: bd2TotalCount,
      emissionTarget: bd2Count,
    },
    bd3: {
      total: bd3TotalCount,
      emissionTarget: bd3Count,
    },
    hp1: {
      total: hp1TotalCount,
      emissionTarget: hp1Count,
    },
  };
  //@ts-ignore
  return (
    Object.keys(billType)
      //@ts-ignore
      .filter((v) => billType[v] !== null) //미사용 현금 모듈 제외
      //@ts-ignore
      .map((v) => countObj[v])
      .reduce(
        (prev, curr) => prev || Boolean(curr.total < curr.emissionTarget),
        false
      )
  );
};

//현금이 충분한지 파악
export const getIsEnoughMoneyLeft = ({
  billType,
  kioskStatus,
}: CustomBillTypeParams) => {
  const { bd1TotalCount, bd2TotalCount, bd3TotalCount, hp1TotalCount } =
    kioskStatus;
  const countObj = {
    bd1: bd1TotalCount,
    bd2: bd2TotalCount,
    bd3: bd3TotalCount,
    hp1: hp1TotalCount,
  };
  //@ts-ignore
  return (
    Object.keys(billType)
      //@ts-ignore
      .filter((v) => billType[v] !== null) //미사용 현금 모듈 제외
      //@ts-ignore
      .map((v) => countObj[v])
      .reduce((prev, curr) => prev && Boolean(curr >= 100), true)
  );
};
export const getCashInfo = ({
  billType,
  kioskStatus,
}: CustomBillTypeParams) => {
  const { bd1TotalCount, bd2TotalCount, bd3TotalCount, hp1TotalCount } =
    kioskStatus;

  const cashInfo = [
    { type: 'bd3', isError: bd3TotalCount < 100, count: bd3TotalCount },
    { type: 'bd2', isError: bd2TotalCount < 100, count: bd2TotalCount },
    { type: 'bd1', isError: bd1TotalCount < 100, count: bd1TotalCount },
    { type: 'hp1', isError: hp1TotalCount < 100, count: hp1TotalCount },
  ];
  const activeBillType = Object.keys(billType)
    //@ts-ignore
    .filter((v) => billType[v] !== null);
  return cashInfo.filter((v) => activeBillType.includes(v.type));
};

export const formatBillType = (price: number | null) => {
  switch (price) {
    case 50000:
      return '오만원권';
    case 10000:
      return '만원권';
    case 5000:
      return '오천원권';
    case 1000:
      return '천원권';
    case 500:
      return '오백원';
    default:
      return '미사용';
  }
};

/**CMD별 Byte Count 계산 */
export const calculateBC = (cmd: number): number => {
  switch (cmd) {
    case 0x10:
    case 0x11:
    case 0x12:
      return 0x0d;
    case 0x13:
    case 0x16:
    case 0x17:
    case 0x18:
    case 0x19:
      return 0x12;
    default:
      console.error(`Unknown command : ${cmd}`);
      return 0x00;
  }
};

/**전달할 패킷의 Frame Check Code 계산 */
export type PacketExcludingFcc = number[];
export const calculateFCC = (data: PacketExcludingFcc): number => {
  return data.reduce((acc, val) => acc ^ val, 0);
};

/**통신 표현값에서 방출기 표시 에러코드로 변환 */
export const convertErrorCode = (number: number) => {
  const errNumber = number?.toString(16);
  switch (errNumber) {
    case 'a0': //Er14
      return {
        code: 'ER01',
        message:
          '지폐가 접혀있습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '20': //Er2
      return {
        code: 'ER02',
        message:
          '지폐가 방출 통로에 걸렸습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '30': //Er3
    case '40': //Er4
      return {
        code: 'ER03',
        message:
          '지폐 겹쳐지거나 방출되지 않았습니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '50': //Er5
    case '60': //Er6
      return {
        code: 'ER04',
        message:
          '지폐 길이 비정상입니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '70': //Er7
      return {
        code: 'ER05',
        message:
          '지폐 적재 불량입니다.\n키오스크 내부에 부착된 에러 메뉴얼 혹은 영상을 따라 조치해주세요.',
      };
    case '10': //Er1
      return {
        code: 'ER06',
        message:
          '지폐가 부족합니다. 고객센터로 연락해주세요. KTP 고객센터:02-6275-8011',
      };
    case '80': //Er8
    case '90': //Er12
      return {
        code: 'ER07',
        message:
          '기기 고장입니다. 고객센터로 연락해주세요. KTP 고객센터:02-6275-8011',
      };
    default:
      return {
        code: '',
        message: '',
      };
  }
};
export const findProperty = (input: any, obj: any): string | undefined => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] === input) {
      return prop;
    }
  }
  return undefined;
};
