import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';

import 'styles/keypad.css';

type Props = {
  setInput: Dispatch<SetStateAction<string>>;
  containerStyle: string;
};

const STRING_KEY = `q w e r t y u i o p a s d f g h j k l z x c v b n m Q W E R T Y U I O P A S D F G H J K L @ Z X C V B N M 1 2 3 4 5 6 7 8 9 0 - / : ; ( ) ₩ & " [ ] { } # % ^ * + =`;
const STRING_OBJECT = `@ . , ? ! ' _ \\ | ~ < > $`;
const MODIFIER_KEY = `shift alt shiftactivated altactivated default`;

function EmailKeypad({ setInput, containerStyle }: Props) {
  const [layoutName, setLayoutName] = useState('default');

  const keyboard = useRef<any>(null);

  useEffect(() => {
    if (keyboard.current) {
      keyboard.current.addButtonTheme(STRING_KEY, 'email-keypad-string');
      keyboard.current.addButtonTheme(MODIFIER_KEY, 'email-keypad-modifier');
      keyboard.current.addButtonTheme(
        'shiftactivated',
        'email-keypad-shiftactivated'
      );
      if (['alt', 'altactivated'].includes(layoutName)) {
        keyboard.current.addButtonTheme(STRING_OBJECT, 'email-kaypad-object');
      }
    }
  });

  const onKeyPress = (button: any) => {
    switch (button) {
      case 'Back':
        setInput((prevInput) => prevInput.slice(0, -1));
        break;
      case 'Clear':
        setInput('');
        break;
      case 'shift':
      case 'shiftactivated':
        setLayoutName((prev) => (prev === 'default' ? 'shift' : 'default'));
        break;
      case 'altactivated':
        setLayoutName((prev) => (prev === 'alt' ? 'altactivated' : 'alt'));
        break;
      case 'alt':
        setLayoutName('alt');
        break;
      case 'default':
        setLayoutName((prev) => (prev === 'default' ? 'alt' : 'default'));
        break;
      default:
        setInput((prevInput) => {
          return prevInput + button;
        });
        break;
    }
  };

  return (
    <div className={`flex ${containerStyle}`}>
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layoutName}
        layout={{
          default: [
            'q w e r t y u i o p',
            'shift a s d f g h j k l',
            'alt @ z x c v b n m Back',
          ],
          shift: [
            'Q W E R T Y U I O P',
            'shiftactivated A S D F G H J K L',
            'alt @ Z X C V B N M Back',
          ],
          alt: [
            '1 2 3 4 5 6 7 8 9 0',
            'altactivated - / : ; ( ) ₩ & "',
            "default @ . , ? ! ' Back",
          ],
          altactivated: [
            '[ ] { } # % ^ * + =',
            'alt _ \\ | ~ < > $',
            "default @ . , ? ! ' Back",
          ],
        }}
        buttonTheme={[
          { class: 'bg-gray', buttons: 'Back' },
          {
            class: 'bg-blue',
            buttons: 'shiftactivated',
          },
        ]}
        display={{
          alt: '123',
          altactivated: '#+=',
          shift: 'shift',
          shiftactivated: 'shift',
          default: 'ABC',
        }}
        maxLength={10}
        onKeyPress={(button) => onKeyPress(button)}
      />
    </div>
  );
}

export default EmailKeypad;
