import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import logo from 'images/common/ktp_logo_blue.png';
import setting from 'images/common/setting.png';
import LoadingView from 'components/common/LoadingView';

type Props = {
  isHeader?: boolean;
  isKtpLogo?: boolean;
  isSetting?: boolean;
  isLoading?: boolean;
  children: ReactNode;
};
function Layout({
  isHeader = true,
  isKtpLogo = true,
  isSetting = false,
  isLoading = false,
  children,
}: Props) {
  const handleContextMenu: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {isHeader && (
        <header
          className='flex justify-between bg-mono-10 p-16'
          onContextMenu={handleContextMenu}
        >
          {isKtpLogo ? (
            <img src={logo} height={46} width={186} alt='ktp_logo' />
          ) : (
            <div></div>
          )}
          {isSetting && (
            <Link to='/setting'>
              <img
                src={setting}
                height={48}
                width={48}
                alt='setting'
                className='opacity-0'
              />
            </Link>
          )}
        </header>
      )}
      <main
        className={`w-full min-h-[1920px] bg-mono-10 p-14 pt-20 relative overflow-hidden`}
        onContextMenu={handleContextMenu}
      >
        {isLoading && <LoadingView />}
        {children}
      </main>
    </>
  );
}

export default Layout;
