import Keyboard from 'react-simple-keyboard';

type Props = {
  onKeyPress: any;
  containerStyle?: string;
};
function NumberKeypad({ onKeyPress, containerStyle = '' }: Props) {
  return (
    <div className={containerStyle}>
      <Keyboard
        layout={{
          default: ['1 2 3', '4 5 6', '7 8 9', 'Clear 0 Back'],
        }}
        buttonTheme={[
          { class: 'bg-gray', buttons: 'Clear Back' },
          {
            class: 'bg-blue',
            buttons: '1 2 3 4 5 6 7 8 9 0',
          },
        ]}
        maxLength={10}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}

export default NumberKeypad;
