import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAtom } from 'jotai';
import { loginInfoAtom } from 'store/globalStateAtom';

function useBlockAccessIfNotLoggedIn() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);

  useEffect(() => {
    const needTogoToLoginPage = Boolean(pathname !== '/' && !loginInfo);
    if (needTogoToLoginPage) {
      navigate('/');
    }
  }, [pathname]);
}

export default useBlockAccessIfNotLoggedIn;
