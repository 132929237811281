import React, { Dispatch, SetStateAction } from 'react';
import Keyboard from 'react-simple-keyboard';

type Props = {
  setInput: Dispatch<SetStateAction<string>>;
  containerStyle: string;
};
function QwertyKeypad({ setInput, containerStyle }: Props) {
  const onKeyPress = (button: any) => {
    if (button === 'Back') {
      setInput((prevInput) => prevInput.slice(0, -1));
      return;
    }
    if (button === 'Clear') {
      setInput('');
      return;
    }
    setInput((prevInput) => {
      return prevInput.length === 10 ? prevInput : prevInput + button;
    });
  };

  return (
    <div className={containerStyle}>
      <Keyboard
        layout={{
          default: [
            '1 2 3 4 5 6 7 8 9 0',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Clear Z X C V B N M Back',
          ],
        }}
        buttonTheme={[
          { class: 'bg-gray', buttons: 'Clear Back' },
          {
            class: 'bg-blue',
            buttons: '1 2 3 4 5 6 7 8 9 0',
          },
        ]}
        maxLength={10}
        onKeyPress={(button) => onKeyPress(button)}
      />
    </div>
  );
}

export default QwertyKeypad;
