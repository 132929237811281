import React from 'react';

function LoadingView() {
  return (
    <div className='fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50'>
      <div className='w-12 h-12 rounded-full border-[10px] border-l-blue-10 border-blue-80 animate-spin'></div>
      <p className='text-white font-bold text-body-01 mt-4 ml-4'>
        Loading . . .
      </p>
    </div>
  );
}

export default LoadingView;
