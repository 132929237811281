import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from 'routes';
import { Provider } from 'jotai';

import Modal from 'components/common/Modal';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <AppRoutes />
        <Modal />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
