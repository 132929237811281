import React from 'react';
import { useTranslation } from 'react-i18next';
import { comma } from 'utils';

type Props = {
  totalCount: string;
  totalPrice: string;
  totalRefund: string;
};
function RefundSummaryTable({ totalCount, totalPrice, totalRefund }: Props) {
  const { t } = useTranslation();

  return (
    <table className='rounded-20 overflow-hidden w-full'>
      <thead className='bg-blue-10 table w-full'>
        <tr>
          <th>{t('refund_count')}</th>
          <th>{t('currency')}</th>
          <th>{t('total_payment')}</th>
          <th className='bg-blue-20'>{t('total_refund')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className='odd:bg-mono-01 even:bg-mono-05 w-full table'>
          <td>{totalCount}</td>
          <td className='text-mono-65'>KRW</td>
          <td>{comma(totalPrice)}</td>
          <td className='text-blue-100 font-semibold'>{comma(totalRefund)}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default RefundSummaryTable;
